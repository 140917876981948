footer{
  height: 10px;
  color: #2d2b2b;
  background-color: rgba(218, 218, 220, 1);
  text-align: center;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  box-shadow: 0 0 2px 1px seashell;
  padding: 15px;
}