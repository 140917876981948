.create-btn {
  background-color: darkblue;
  color: white;
  border: 5px solid saddlebrown;

}


.item {
  font-weight: bold;
  text-transform: capitalize;
}

.RaImageField-image-70 {
  width: 100%;

}

td > div > img {
  height: 100%;
}


.textarea {
  background-color: #dddddd;
  color: #666666;
  padding: 1em;
  border-radius: 10px;
  border: 2px solid transparent;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  width: 400px;
  height: 150px;
  transition: all 0.2s;
}

.textarea:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.textarea:focus {
  cursor: text;
  color: #333333;
  background-color: white;
  border-color: #333333;
}
