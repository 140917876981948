.show-container {

  .show {
    overflow: hidden;
    display: grid;
    grid-template-rows: 0.2fr .5fr  30px 0.1fr;
    grid-template-columns: auto  1fr auto;
    gap: 1px;
    border-radius: 10px;
    margin: 0px 10px;
    height: 100%;
    width: 100%;

    .show-bcg {
      border-radius: 10px;
      text-align: center;
      gap: 5px;
      padding: 10px 0;
      margin: 0px 10px;
      height: 100%;

    }
  }
}


@media only screen and (max-width: 1320px) {
  .show {
    //height: 65vh;

  }
}


.created, .posted {
  grid-row: 3/4;
  margin: 0;
  font-size: 12px;
height: 80px;
}

.head {
  display: flex;
  flex-direction: column;
  grid-row: 1/2;
min-height: 80px;
  .halite {
    font-weight: lighter;
    color: darkblue;
    padding: 0;
    margin: 0;

  }
}

.img-container {
  grid-column: 1/-1;
  grid-row: 2/3;
  height: 100%;
  img {
    object-fit: contain;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


