.drop-img {
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
}


.input-file{
  max-width: 650px;

}
.cropped{
min-width: 500px;
  min-height: 550px;
  box-shadow: -1px 3px 9px 2px silver;
  text-align: center;
  padding:  10px ;
  border-radius: 10px;
  margin: 0px 10px;
  button{
    margin: 0 10px;
  }

}

