header.MuiPaper-root button[aria-label="Refresh"] {
  display: none;
  width: 0;


}
.MuiDrawer-docked {
  flex: 0 0 auto;
  display: none;

}
MuiPaper-root MuiDrawer-paper RaSidebar-paper-15 MuiDrawer-paperAnchorLeft RaSidebar-paperAnchorLeft-16 RaSidebar-drawerPaper-25 RaSidebar-drawerPaper-27 MuiDrawer-paperAnchorDockedLeft RaSidebar-paperAnchorDockedLeft-20 MuiPaper-elevation0{
  display: none;
}


.appbar-form{
  padding: 10px;
  min-height: 80px;
  input{
    width: 50px;
  }
}
.MuiToolbar-root {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

}

.RaSidebar-drawerPaper-27 {
  width: 0px;
}