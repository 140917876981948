* {
  box-sizing: border-box;
  user-select: none;
}

body {
  margin: 0 50px;
  //font-family: Calibri,"Segoe UI";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;

}

html {
  font-family: "Airbnb Cereal App Book";
  line-height: 1.6;
  scroll-snap-destination: center;
  scroll-behavior: smooth;

}

.frame {
  margin-bottom: 10px;
  margin-top: 20px;
  box-shadow: -1px 3px 9px 2px silver

  /*background-color: paleturquoise;*/
}

a {
  color: white;
  text-decoration: none;

}

main {
  height: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex-row {
  display: flex;
}

.space-between {
  justify-content: space-between;

}

.flex-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
  justify-content: center;

}

.trans {
  opacity: 0;
  height: 0;
}

ul {
  border-radius: 5px;
  color: darkblue;
  max-width: 300px;
  margin: 0;
  list-style-type: decimal;
  //padding: 10px;
}

li {
  margin: 4px;
}

.btn {
  cursor: pointer;
  //margin-top: 10px;
  margin-bottom: 0;
  background-color: black;
  color: aliceblue;
  padding: 0;

  border-radius: 5px;
  box-shadow: -1px 3px 9px 2px silver;
  align-items: center;
}



.form-span {
  margin: 10px 0;
}

form > input {
  padding: 10px;
  margin-top: 10px;
}

form {
  align-items: center;
  /*margin: 0 auto;*/
  width: 100%;
  align-content: center;
}

img {
  object-fit: contain;
  animation: fadeIn;
  animation-duration: 1s;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  text-shadow: none;
  font-weight: 550;

}

.error {
  height: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.loading {
  animation: 2s bounce ease infinite;
}
.absolute{
  position: absolute;
z-index: 1000;
}


@keyframes bounce {
  50% {
    transform: translateX(-10px);
  }
}
